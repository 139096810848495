<template>
  <!-- Body -->
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <!-- Form -->

    <b-form class="p-2" 
      @submit.prevent="handleSubmit(onSubmit)" 
      @reset.prevent="resetForm"
    >
      
      <!-- Upload CV -->
      <validation-provider #default="validationContext" name=" ">
        <b-form-group>
          <label for="cv">{{ $t('jobs.apply.cv') }}</label>
          <b-form-file
            id="cv"
            v-model="file.cv"
            accept=".pdf, .doc, .docx"
            class="pointer"
            name=" "
            :placeholder="$t('form-create-item.file-placeholder')"
            :browse-text="$t('form-create-item.file-button')"
            :state="getValidationState(validationContext)"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Upload Cover Letter -->
      <b-form-group>
        <label for="cover">
          {{ $t('jobs.apply.cover') }}
        </label>
        <b-form-file
          id="cover"
          v-model="file.coverLetter"
          accept=".pdf, .doc, .docx"
          class="pointer"
          :placeholder="$t('form-create-item.file-placeholder')"
          :browse-text="$t('form-create-item.file-button')"
        />
      </b-form-group>

      <!-- Message -->
      <validation-provider #default="validationContext" :name="$t('jobs.apply.message')">
        <b-form-group label-for="message">
          <label>{{ $t('jobs.apply.message') }}</label>
          <b-form-textarea
            id="message"
            v-model="message"
            :name="message"
            :placeholder="$t('form-create-item.message-placeholder')"
            rows="8"
            :state="getValidationState(validationContext)"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <div class="float-right mb-2">
        <b-button variant="primary" type="submit">
          {{ $t('form.actions.submit') }}
        </b-button>
      </div>
      
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { required, max } from '@validations';

export default {
  name: 'JobApply',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    job: { type: String, default: '' },
  },
  data() {
    return {
      file: {},
      message: null,
      required,
      max,
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  methods: {
    async onSubmit() {
      try {
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'jobApplicants',
            requestConfig: {
              key: this.job,
              jobKey: this.job,
              message: this.message,
            },
          },
          file: {
            cv: this.file.cv,
            coverLetter: this.file.coverLetter,
          },
        });
        this.notifySuccess(this.$t('share.notify-success'));
        this.$emit('close-modal', false);
        this.$emit('new-applicant'); 
      } catch (error) {
        this.notifyError(this.$t('share.notify-error'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-editor::v-deep {
  height: auto;
  .ql-editor {
    min-height: 96px;
  }
}
</style>
