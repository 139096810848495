import { render, staticRenderFns } from "./JobApplyForm.vue?vue&type=template&id=ddc00df2&scoped=true&"
import script from "./JobApplyForm.vue?vue&type=script&lang=js&"
export * from "./JobApplyForm.vue?vue&type=script&lang=js&"
import style0 from "./JobApplyForm.vue?vue&type=style&index=0&id=ddc00df2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddc00df2",
  null
  
)

export default component.exports